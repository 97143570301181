import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { AuthService } from 'app/core/auth/auth.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'state-select',
  standalone: true,
  imports: [CommonModule, FormsModule, NgSelectModule],
  templateUrl: './state-select.component.html',
  styleUrl: './state-select.component.scss'
})
export class StateSelectComponent {

  @Input() countryId: number = 1;//1 for US

  @Input() value: string;

  @Input() disabled: boolean = false;

  @Output() onChange = new EventEmitter<any>();

  states: { id: number, name: string; abbreviation: string }[] = [];

  constructor(private _authService: AuthService,
    private _toaster: ToastrService
  ){}

  ngOnInit(): void {
    this.getStates();
  }

  getStates(id:number = this.countryId) {
    this._authService.getStatesByCountryId(id).subscribe(response=>{
      if(response.http_status==200){
        this.states = response.data.result;
      }else{
        this._toaster.error("Failed to fetch states");
      }
    })
  }

  trackByFn(item: any): any {
    return item ? item.id : null;
  }
  
  onChangeEvent(event: any): void {
    this.value = event;
    this.onChange.emit(event);
  }

}
