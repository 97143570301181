
import { StatusValueEnum } from "app/core/enums/common.enum";
import { CallLog } from "./reports.models";

export class SIPdetailsResponse {

  public sip_uri:string;

  public password:string;

  public websocket_uri:string;

  public via_transport:string;

  public register_server:string;

  public contact_uri:string;

  public status:string=StatusValueEnum.OFFLINE;

  public associated_username:string;

  public agent_name:string;

  public sip_id:string;

  public status_id:number;

  tenant_subscription:boolean;

  user_subscription:boolean;

  auto_open:boolean;

  auto_open_add_contact:boolean;

  public send_as:string = '';

  public upcoming_sip:number

}


export class UserStatusUpdateRequest {

 public customer: Array<number> = []

 public role: number;

 public email: string;

//public call_start_time: string;

public call_end_time: string;

public auto_open: boolean;

public auto_open_add_contact : boolean;

public status: string;
}

export class callDetailsResponse {
  call_id: string | null;
  sw_project_id: string;
  simplyfuse_number: string;
  users_number: string;
  simplyfuse_number_org_name: string;
  simplyfuse_number_suborg_name: string;
  simplyfuse_number_did_name: string;
  simplyfuse_number_sid: string;
  users_name: string | null;
  users_email: string | null;
  users_mobile: string | null;
  users_phone: string | null;
  company_name: string | null;
  documents: any | null;
  users_call_history: CallLog[];
  is_update: boolean;
  simplyfuse_number_suborg_id: number;
  company_id: number | null;
  users_id: number | null;
}

export class Documents {

  public id: number;

  public type: string;

  public created_on: string;

  public reason: string;

  public unique_number: string;
}

export class STandSOresponseFromAgentUi{

  public id: number;

  public type: string;

  public created_on: Date;

  public reason: string;

  public unique_number: number;

}
 export class CallPanelOpenModel{
 
   public isOpen:boolean = false

   public selectedTab:number

 }

 export class CallCenterSpec{

  public left:number;

  public top:number;

  public height:number;

  public width:number;

 }

 export class CallNotification{

   public to_number: string;

   public message: string;

   public type: string;

   public timeout: string;

   public tenant_customer_name: string;

   public call_id: string;

   public from_number: string;

   public action: string;

   public tenant_customer_contact_name: string;

   public timestamp: string;

   public notificationAction:string;

   public incoming_route_name:string =''

   public project_id:string = ''

   public call_type:string = ''

   call_recording_enabled?: boolean;

   

 }

 export class TransferCallPayload{

  call_sid: string;

  project_id: string;

  transfered_to: number;

  transfered_by: number;

  from_number: string;

  to_number: string;

  call_recording_enabled: string;

  incoming_route_name: string;

  transfer_option: string;

  call_type: string;

 }

 export class InboundMergeAgentPayload{

  call_id: string;

  call_merged_from: number;

  call_merged_to: number;

  project_id: string;

  from_number: string;

 }

 export class InboundMergeCustomerPayload{

  call_merged_from: number;

  call_merged_from_send_as: string;

  to: string;

  call_id: string;

 }



