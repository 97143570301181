import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { concat, debounceTime, distinctUntilChanged, filter, map, Observable, of, Subject, switchMap } from 'rxjs';
import { Company } from 'app/modules/tenant-customers/models/companies.models';
import { CompanyService } from 'app/modules/tenant-customers/services/company.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@Component({
  selector: 'customer-select',
  standalone: true,
  imports: [CommonModule, NgSelectModule, FormsModule, NgxSkeletonLoaderModule],
  templateUrl: './customer-select.component.html',
  styleUrl: './customer-select.component.scss'
})

export class CustomerSelectComponent {

  private _value: any[];

  get value(): any[] { return this._value; }

  @Input() set value(val: any[]) {
    console.log('$val', val)
    if (val) {
      this._value = val.map((item: any) => { return { id: item._id, name: item.name } });
      // if (val > 0 && this.selected !== val) {
      //   this.setValue(val);
      // }
      //else {
      console.log('value', this._value)
      this._selected = this.value ? this.value.map((item: any) => item.id)[0] : null;
      console.log('selected', this.selected)
      //}
    }
  };

  private _selected: any[];

  public get selected(): any[] { return this._selected; }

  public set selected(value: any[]) {
    this._selected = value;
    if (!value) {
      this.valueChange?.emit(value);
    }

  }

  @Input() readonly: boolean = false;

  @Output() valueChange = new EventEmitter<any[]>();

  @Output() change = new EventEmitter<any>();

  @Input() fetchWhenOpen: boolean = false;

  public firstOpen: boolean = true;

  public options: Observable<Company[]>;

  selectedOptions: Company[];

  private searchSubject = new Subject<string>();

  public textInput = new Subject<string>();

  public initialRows: Company[] = []

  private initialLoad: boolean = true;

  public loading: boolean = false;

  private offSet: number = 0;

  public rows: Company[];

  public totalCount: number = 0;

  public currentCount: number = 0;

  private searchText: string = '';

  @Output() clearItem = new EventEmitter<boolean>

  @Input() isParentNullFilter: boolean = false;

  constructor(private _tenatCustService: CompanyService) { }

  ngOnInit() {
    if (!this.fetchWhenOpen) {
      this.options = this.getData()
    }
    this.searchSubject.pipe(
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe(query => {
      this.options = this.getData(query);
    });
  }

  loadInitial() {
    this.options = concat(this.getData(), this.textInput.pipe(
      filter(res => res !== null && res.length >= 2),
      distinctUntilChanged(),
      switchMap(term => this.getData(term))
    ));
  }

  getData(search?: any, loadMore: boolean = false) {
    let option = {
      sort: [{ colname: 'name', direction: 'asc' }],
      filters: search? [{
          conditions: [{ colname: 'name', condition: 'contains', value: search, operator: 'AND' }
          ],
          operator: 'AND'
        }] : []
    };
    if(this.isParentNullFilter){
      option.filters.push(...[{
        conditions: [
          {
            colname: "parent_company",
            condition: "is_null",
            value: true,
            operator: "AND"
          }
        ],
        "operator": "AND"
      }]);
    }
    return this._tenatCustService.getAll(option, this.offSet, 10).pipe(
      map(resp => {
        if (resp.http_status == 200) {
          this.totalCount = resp.data.total_count;
          this.currentCount += resp.data.result.length;

          if (this.value) {
            resp.data.result = this.mergeArraysUniqueById(resp.data.result, this.value);
          }
          if (this.initialLoad) {
            this.initialRows = resp.data.result
            this.rows = resp.data.result
            this.initialLoad = false
          }
        }

        return resp.data.result;
      })
    );
  }

  selectOpen() {
    if (this.fetchWhenOpen && (!this.options || this.firstOpen)) {
      this.loadInitial();
      this.firstOpen = false;
    }
  }

  trackByFn(item: Company) { return item.id; }



  onValueChanged(value: any) {

    if (value) {
      const val = [{ _id: value.id, name: value.name }];

       this.valueChange.emit(val);

      // this.change?.emit(val);
      console.log('-val', val)
      this.selectedOptions = value
      this.options = of(this.mergeArraysUniqueById(this.initialRows, this.selectedOptions))
    }

  }
  mergeArraysUniqueById<T extends { id: number }>(arr1: T[], arr2: T[]): T[] {
    const map = new Map<number, T>();
    arr1.concat(arr2).forEach(item => map.set(item.id, item));
    return Array.from(map.values());
  }

  onSearch(query: string): void {

    this.searchText = query;
    this.offSet = 0;
    this.currentCount = 0
    if (query != null && query.length >= 2) {
      this.searchSubject.next(query);
    }
    else {
      this.options = of(this.initialRows)
    }
  }

  loadMoreItems() {
    console.log('currentcount: ' + this.currentCount, 'totalcount: ' + this.totalCount)
    if (this.currentCount != this.totalCount) {
      this.loading = true
      this.offSet = this.currentCount;
      this.rows = [...this.rows, ...new Array(2).fill({ id: null, name: null })];
      this.options = of(this.rows)
      this.getData(this.searchText, true).subscribe(res => {
        setTimeout(() => {
          this.loading = false
          this.rows = this.rows.filter(item => item.id !== null);
          this.rows = [...this.rows, ...res];
          this.options = of(this.rows)
        }, 1000);

      })

    }

  }
  onClear() {
    this.searchText = '';
    this.currentCount = 0
    this.offSet = 0;
    this.rows = this.initialRows;
    this.initialLoad = true;
    this.options = of(this.initialRows);
    this.clearItem.emit(true);
  }

}
