import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from 'app/core/services/base.service';
import { InboundMergeAgentPayload, InboundMergeCustomerPayload, SIPdetailsResponse, TransferCallPayload, UserStatusUpdateRequest, callDetailsResponse } from '../models/call-center.models';
import { BehaviorSubject, Subject } from 'rxjs';
import { DataFilterResponse } from 'app/core/models/grid-filter.models';

@Injectable({
  providedIn: 'root'
})
export class CallCenterService extends BaseService {


  public isalwaysOpen:boolean= false;

  public isalwaysOpenAddContact:boolean= false;

  public isOnGoingStForHold: boolean = false;
  

  public isOnGoingSoForHold: boolean = false;

  public openIncomingDetPopup = new BehaviorSubject(false)

 // public agentStatusChange: Subject<{status:any,updateStatus:boolean}> = new Subject<{status:any,updateStatus:boolean}>();

  public isOpenCallCenter : boolean = false

  //public isActiveCallInbound : boolean = false

 // public isActiveCallOutbound : boolean = false

  constructor(private http: HttpClient) { super(); }

  /**
   * 
   * this api return connection credentials and agent details
   */

  getAgentConnectionDetById() {
    
    return this.http.get<DataFilterResponse<SIPdetailsResponse>>(this.baseURL+`/call/connect/${this.userId}/user/`);
  }

  getCallDetails(callerId:string) {

    return this.http.get<DataFilterResponse<callDetailsResponse>>(this.baseURL+`/calls/${callerId}`);
  }

  // updateLastCallTime(sipdetailsresponse: UserStatusUpdateRequest) {

  //   return this.http.patch(this.baseURL+`/sip/${this.userId}/user/`, sipdetailsresponse);
  // }

  updateUserStatus(bosipdetailsresponse: UserStatusUpdateRequest)
  {
    return this.http.patch<DataFilterResponse<any>>(this.baseURL+`/users/${this.userId}/`,bosipdetailsresponse)
  }

  getNonCallCenterDetails(toNumber: string, fromNumber:string) {
    return this.http.get<DataFilterResponse<callDetailsResponse>>(this.baseURL+`/did/call_center/disable/${toNumber}/${fromNumber}/`);
  }

  putCallOnHold(body: { call_sid: string, to_number:string }){  

    // let headers = new HttpHeaders();
    // headers= headers.set('content-type', 'application/json');
   return this.http.post<DataFilterResponse<any>>(this.webhookURL+`/hold`,body);
   // return this.http.post(`https://9f91-202-88-254-119.ngrok-free.app/api/pbx/hold?call_type=`+type,body);

   
  }

  releaseCallFromHold( body: { call_sid: string, to_number:string, sip_username:string, call_center_enabled:boolean} ){
    return this.http.post<DataFilterResponse<any>>(this.webhookURL+`/unhold`,body);
  }

  endCallFromHold(body: { call_sid: string, to_number:string,username:string}){
    
    return this.http.post<DataFilterResponse<any>>(this.webhookURL+`/end_call_from_hold`,body);
  }

  getNonCallCenterDetailsOutbound(toNumber: string, fromNumber:string) {
    return this.http.get<DataFilterResponse<callDetailsResponse>>(this.baseURL+`/call_details/${toNumber}/${fromNumber}/`);
  }

  getSIPCallDetails(body: {from_number: string, sip_id: string} ){
    
    return this.http.post<DataFilterResponse<any>>(this.baseURL+`/outbound_call_sip/`,body);
  }

  getSIPDetailsbyUserName(body: {from_sip: string, to_sip: string} ){
  
    return this.http.post<DataFilterResponse<any>>(this.baseURL+`/get-agent-details/`,body);
  }

  getCallIdOutbound(noFrom: string, noTo:string ):any{
    return this.http.get(this.webhookURL+`/get_call_id/${noFrom}/${noTo}`)
  }

  connectToCall(body: { call_sid: string, to_number:string, sip_username:string, call_center_enabled:boolean})
  {
    return this.http.post<DataFilterResponse<any>>(this.webhookURL+'/connect/call',body)
  }

  //transfer call
  transferCall(body: TransferCallPayload){
    return this.http.post<DataFilterResponse<{status:boolean,call_transfered_to: string}>>(this.webhookURL+'/transfer/call',body);
  }

  mergeAgentToCall(body: InboundMergeAgentPayload, call_already_in_conference: boolean = false){
    let params = new HttpParams()
                .set('call_already_in_conference', call_already_in_conference);
              
    return this.http.post<DataFilterResponse<{status:boolean}>>(this.webhookURL+'/add_agent_conference',body, {params: params});
  }

  mergeCustomerToCall(body: InboundMergeCustomerPayload){
    return this.http.post<DataFilterResponse<{status:boolean}>>(this.webhookURL+'/add_customer_conference',body);
  }
}
